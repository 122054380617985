import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import editDiv from "@/view/exam/activeEdit.vue";
import { getActivePage, exportExamAllData, createExamAllClassExcel } from "@/api/api";
export default {
  name: "activeList",
  components: {
    editDiv
  },
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    closeDiv() {
      console.log('closeDIV');
    },
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    createExamAllData(active_id) {
      createExamAllClassExcel({
        active_id: active_id
      }).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    exportExamAllData(active_id) {
      exportExamAllData({
        active_id: active_id
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        });
        const link = document.createElement("a");
        link.download = '多科成绩.xls'; //下载名字
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link); // 将a标签追加到文档对象中
        link.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      });
    },
    showEdit(active_id) {
      let url = '/examData/activeExamScoreOne?active_id=' + active_id;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examData/activeExamScoreOne",
        query: {
          active_id: active_id
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};